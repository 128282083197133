/* Contact.css */

.react-tel-input-container {
    display: flex;
    align-items: center;
  }
  
  .form-control {
    flex-grow: 1; /* Allow the input to grow and take up available space */
  }
  
  .error-message {
    color: red;
  }
  .PhoneInputCountrySelect
{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
  margin-left: -22px;
}
.PhoneInputCountry
{
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  margin-top: -10px;
}
  