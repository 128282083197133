body {
  font-family: "Assistant", "Open Sans", "HelveticaNeue", "ProximaNova", sans-serif !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #f4f4f4 !important;
  background: white !important;
}
/* Default Styles */
.logo, .logo-image {
  height: 60px !important;
  position: absolute;
  left: 8px;
}



.header-content {
  max-height: calc(100vh - 52px); /* Adjust based on your header height */
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}




/* styles.css */
/* Default Styles */




.error-message{
  color: red !important;
  font-family: "Assistant", "Open Sans", "HelveticaNeue", "ProximaNova", sans-serif !important;
  font-size: 12px;
  margin-top: 1px;
}

.error-message-lastName {
  color: red !important;
  font-family: "Assistant", "Open Sans", "HelveticaNeue", "ProximaNova", sans-serif !important;
  font-size: 12px;
  margin-top: 1px;
  margin-top: -123px;
  margin-bottom: -60px;
  margin-left: 690px

}

.error-message-firstName {
  color: red;
  font-family: "Assistant", "Open Sans", "HelveticaNeue", "ProximaNova", sans-serif !important;
  font-size: 12px; 
  margin-top: -123px;
  margin-bottom: -60px;
  margin-left: 475px;
}



header {
  background-color: #fff !important;
  color: #333 !important;
  padding: 10px 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  /* height: 85px !important; */


  top: 0;
  left: 0;
  width: 100%;
  height: 85px;
  /* min-width: 1024px; */
  /* z-index: 9997; */
}

h1 {
  margin-top: 50px !important;
  font-family: 'Montserrat' !important;
  font-size: 32px !important;
  text-align: center !important;
  color: #383738 !important;
  max-width: 100% !important;
  word-wrap: break-word !important;
  /* white-space: nowrap !important; */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-left: 40px;
  margin-right: 40px;
}

p {
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
  text-align: center !important;
  margin: 20px !important;
  color: #383738 !important;
  max-width: 100% !important;
  word-wrap: break-word !important;
  /* white-space: nowrap !important; */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.form-container {
  max-width: 400px !important;
  width: 90% !important;
  margin: 20px auto !important;
  text-align: center !important;
  /* padding: 20px !important; */
  margin-top: 35px !important;
}

input {
  width: calc(100% - 20px) !important;
  padding: 10px !important;
  margin-bottom: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
}


button {
  background-color: #ffdf00 !important;
  color: #000000 !important;
  padding: 12px 40px !important; /* Adjust padding as needed */
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-size: 14px !important; /* Adjust font size as needed */
  transition: background-color 0.3s, box-shadow 0.3s !important;
  width: auto !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  margin-top: 18px ;
  margin-top: 18px !important;
  font-family: 'Montserrat' !important;

}


.firstNamebutton {
  background-color: #ffdf00 !important;
  color: #000000 !important;
  padding: 12px 40px !important; /* Adjust padding as needed */
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-size: 14px !important; /* Adjust font size as needed */
  transition: background-color 0.3s, box-shadow 0.3s !important;
  width: auto !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  margin-top: 18px !important;
  font-family: 'Montserrat' !important;
  width: 113px !important;
  margin-left: 36%;
}

button:hover {
  background-color: #ffdf00 !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5) !important;
}

@media screen and (max-width: 768px) {
  .form-container {
      width: 100% !important;
      text-align: center !important;
  }
  button {
      width: 100% !important;
  }
}

.my-input,
.my-input-firstName,
.my-input-lastName {
  font-family: 'Montserrat' !important;
  font-size: 14px !important;

}



.nav-container {
  position: relative;
  text-align: center !important;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin-left: 46%;
  margin-top: -45px;
}

.nav-image {
  width: 78px;
  height: 78px;
  position: absolute;
  left: 50%;
  margin-left: -38px;
  bottom: 0px;
  border-radius: 50%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white;
  background-position-x: 94%;
  background-position-y: 12px;
  background-repeat: no-repeat;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  text-indent: 0.5px;
  margin-left: 30px;
}







select
{
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-image: none;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 12px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
    padding-right: 1rem;
  padding-right: 1rem;
  padding-right: 1rem;
  padding-right: 3rem;
  /* text-indent:22.5px; */
  text-indent: 0.5px;
  margin-left: 30px;
}
  
.dropdown {
    text-align: center;
}

.center-button {
   
    justify-content: center;
    align-items: center;
}
textarea {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical; /* Allow vertical resizing of textarea */
    min-height: 100px; /* Set a minimum height for better appearance */
  }

  .infoContainer
{
  max-width: 500px !important;
  width: 80% !important;
  margin: 20px auto !important;
  text-align: center !important;
  padding: 20px !important;
  border-radius: 8px !important;
  /* background-color: #fff !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important; */
}

.requirementsContainer
{
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat' !important;
  /* align-items: center; */
  max-width: 400px !important;
  width: 27% !important;
  margin: 20px auto !important;
  text-align: center !important;
  padding: 20px !important;
  border-radius: 8px !important;
  /* background-color: #fff !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important; */
}

.availabilityContainer
{
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat' !important;
  /* align-items: center; */
  max-width: 250px !important;
  width: 27% !important;
  margin: 20px auto !important;
  text-align: center !important;
  padding: 20px !important;
  border-radius: 8px !important;
  /* background-color: #fff !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important; */
}

.contact input {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  outline: 1px solid #24ba9f;
  float: left;
  }
  .contact label
  {
  font-size: 18px;
  text-align: left;
  
  text-align: left;
  line-height: normal;
  padding-left: 64px;
  }
  .form-container
  {
  max-width: 400px;
  width: 27%;
  margin: 20px auto;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  /* background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  }
  
    label
  {
  display: block;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  margin-top: 21px;
  text-align: left;
  }
  
    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  
    .checkmark {
      position: absolute;
      top: 0;
      left: 20px;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  
    label:hover .checkmark {
      background-color: #ddd;
    }
  
    input[type="checkbox"]:checked + .checkmark {
      background-color: #ffdf00;
    }
  
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  
    input[type="checkbox"]:checked + .checkmark:after {
      display: block;
    }
  
    .checkmark:after {
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    
    .form-container-timing
    {
      max-width: 400px !important;
      width: 22% !important;
      margin: 20px auto !important;
      text-align: center !important;
      padding: 20px !important;
      border-radius: 8px !important;
    
    
    }
    
    .form-container-timing label
    {
      font-size: 18px !important;
      text-align: left !important;
      text-align: center !important;
      line-height: normal !important;
      padding-left: 5px !important;
    }
.ptag {
	font-size: 11px !important;
	margin: 20px 0px !important;
	margin-top: 40px !important;
}


/* Existing styles */

/* Add a media query for screens smaller than 768px (typical mobile devices) */
@media screen and (max-width: 768px) {
  body {
    padding: 0 !important;
    
  }
  




  .nav-container {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: -50px !important;
    text-align: center !important;
  }


  h1 {
    margin-top: 20px !important;
    font-size: 28px !important;
  }

  p {
    margin: 10px !important;
  }

  .form-container {
    width: 90% !important;
    text-align: center !important;
  }

  input {
    width: calc(100% - 20px) !important;
    margin-left: 0 !important;
  }

  button,
  .firstNamebutton {
    /* margin-left: 0 !important; */
    /* width: 100% !important; */
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    /* margin-top: 18px; */
    width: 40% !important;
    
  }
  .requirementsButton{
    width: 71% !important;
  }

  .infoContainer,
  .requirementsContainer,
  .form-container-timing {
    width: 90% !important;
    text-align: center !important;
  }

  .ptag {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  /* Additional adjustments as needed */
}


.buttonBussinessSize{
  margin-top: 40px;
}



/* .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: #ffdf00 !important;
  transition: var(--bs-progress-bar-transition);
} */


.ProgressBar {
  --bs-progress-bar-bg: #ffdf00 !important;

}


.ProgressBarCompleted {

  --bs-progress-bar-bg: #b2fd0d !important;

}





/* @media (min-width: 1200px) {
  .h1, h1 {
    font-size: 2.5rem;
    margin-top: 100px !important;
  }
} */


.backButton {
  position: absolute !important;
  top: 10px; /* Adjust top position as needed */
  left: 10px; /* Adjust left position as needed */
  width: 50px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  background: transparent !important;
  border: none !important;
  color: transparent !important;
  outline: none !important;
  z-index: 1 !important;
  cursor: pointer !important;
  filter: brightness(-15.2);
}









/* Adjust position and dimensions for different screen sizes */
/* Mobile View */
@media (max-width: 767px) {
  .backButton {
    top: 20px;
    left: 0px;
    width: 52px !important;
    height: 52px !important;
    margin-top: 115px
  }


}

@media  (min-width: 768px) and (max-width: 1023px) {
  .backButton {
    top: 20px;
    left: 20px;
    width: 55px !important;
    height: 55px !important;
    margin-top: 152px;
  }

}




@media (min-width: 1024px) {
  .backButton {
    top: 30px;
    left: 30px;
    width: 50px !important;
    height: 50px !important;
    margin-top: 145px;
  }

}


