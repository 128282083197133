/* Availability.css */
.time-slots {
    display: flex;
    flex-wrap: wrap;
  }
  
  .labelavailability {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .labelavailability input[type="checkbox"] {
    margin-right: 8px; /* Adjust spacing between checkbox and label */
  }
  
  @media (max-width: 768px) {
    .labelavailability {
      width: 100%;
    }
  
    .labelavailability input[type="checkbox"] {
      margin-right: 8px; /* Adjust spacing for mobile view */
    }
  }
  
  @media (min-width: 769px) {
    .labelavailability {
      width: 50%; /* Show two checkboxes and labels per row in desktop view */
    }
  }
  